import "./src/css/_style.scss"
import "./src/css/_global.scss"

// import { enableVisualEditing } from '@sanity/visual-editing';
// import sanityClient from './src/sanityClient';
//
// enableVisualEditing({
//   client: sanityClient,
//   projectId: process.env.GATSBY_SANITY_PROJECT_ID,
//   dataset: process.env.GATSBY_SANITY_DATASET
// });

export const wrapRootElement = ({ element }) => element;